


































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { ComponentType, PaginatedQueryResult } from '@/models'
import draggable from 'vuedraggable'
import gql from 'graphql-tag'


@Component({
  components: {
    draggable,
    Loading
  },
  apollo: {
    result: {
      query () {
        const { queryName, queryFields } = this.componentType as ComponentType<any>
        return gql`query paginated_result ($page: BigInt, $limit: BigInt, $filter: String, $environmentId : ID) {
          result: ${queryName} (page: $page, limit: $limit, filter: $filter, environmentId: $environmentId) {
            _id
            totalCount
            totalPages
            hasNextPage
            hasPreviousPage
            items ${queryFields}
          }
        }`
      },
      variables () {
        return {
          environmentId: this.environmentId,
          page: 1,
          limit: 20,
          filter: this.searchQuery
        }
      }
    }
  }
})
export default class AdminDesignComponentSelect extends Vue {
  @Prop({ type: Object, required: true }) componentType !: ComponentType<any>
  @Prop({ type: String, required: true }) environmentId !: string

  mode = 'select'
  searchQuery = ''

  result : PaginatedQueryResult<any> | null = null

  get components () {
    if (!this.result) return null
    if (this.componentType.transformResult) {
      return this.result.items.map(this.componentType.transformResult)
    }
    return this.result.items || []
  }

  get viewComponents () {
    if (!this.components) return []
    return this.components.map(c => ({
      sizeSmall: '12',
      sizeMedium: '12',
      sizeLarge: '12',
      type: this.componentType.name,
      [`${this.componentType.name}Id`]: c._id
    }))
  }

  addComponent (component : any) {
    this.$emit('addComponent', {
      sizeSmall: '12',
      sizeMedium: '12',
      sizeLarge: '12',
      type: this.componentType.name,
      [`${this.componentType.name}Id`]: component._id
    })
  }

  createNew () {
    this.mode = 'create'
  }

  handleCreate (component : any) {
    this.mode = 'select'
    if (component && component._id) {
      this.$emit('addComponent', {
        sizeSmall: '12',
        sizeMedium: '12',
        sizeLarge: '12',
        type: this.componentType.name,
        [`${this.componentType.name}Id`]: component._id
      }, component._id)
    }
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} creado con éxito!`,
      color: ''
    })
  }

  handleError (e : any) {
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }

  onMove () {
    return false
  }
}
