import gql from 'graphql-tag'

export const ViewUpdateFragment = gql`
  fragment ViewUpdate on View {
    name
    title
    path
    roles
    titleColor
    titleBackground
    hideBorders
    backButton
    printButton
    backLink
    previewParameters
    useCustomCss
    customCss
    items {
      sizeSmall
      sizeMedium
      sizeLarge
      type
      formId
      tableId
      indicatorId
      chartId
      buttonId
      blockId
      fullSize
      subItems
      content
      hideBorders
      forceBorders
      viewType
      tabName
      hidePrint
      cssClass
      helpBlockId
      titleColor
      titleBackground
      touchless
    }
  }
`

export const ViewCreateFragment = gql`
  fragment ViewCreate on View {
    _id
    environmentId
    createdAt
  }
`

export const ViewFragment = gql`
  fragment View on View {
    ...ViewCreate
    ...ViewUpdate
  }

  ${ViewCreateFragment}
  ${ViewUpdateFragment}
`
